<template>
    <div class="zt-page-content">
        <div class="zt-block" style="height: calc(100% - 132px)">
            <!-- <el-row type="flex">
                <el-col>
                    <span class="label">应收总款</span>
                    <span class="value">{{payInfo.total}}</span>
                </el-col>
                <el-col>
                    <span class="label">待确认收款</span>
                    <span class="value">{{payInfo.todo}}</span>
                </el-col>
                <el-col>
                    <span class="label">已确认收款</span>
                    <span class="value">{{payInfo.done}}</span>
                </el-col>
                <el-col>
                    <span class="label">剩余可发起付款</span>
                    <span class="value">{{payInfo.rest}}</span>
                </el-col>
            </el-row> -->
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="payedAt" label="交易时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.payedAt | moment("YYYY-MM-DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receivInfo" label="收款账户">
                        <template slot-scope="scope">
                            <div v-if="scope.row.receivInfo">{{scope.row.receivInfo.account}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receivInfo" label="收款银行">
                        <template slot-scope="scope">
                            <div v-if="scope.row.receivInfo">{{scope.row.receivInfo.bank}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="收款金额">
                        <template slot-scope="scope">
                            <div>{{scope.row.amount | priceFilt}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state=='WAITCONFIRM'">等待确认收款</div>
                            <div v-if="scope.row.state=='SUCCESS'">支付成功</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="confirmCompId" label="操作">
                        <template slot-scope="scope">
                            <el-button @click="view(scope.row)" style="color:#5074EE;" type="text">查看详情</el-button>
                            <el-button v-if="scope.row.confirmCompId==comp.id && scope.row.state=='WAITCONFIRM' || order_type=='fx' && scope.row.state=='WAITCONFIRM'"
                             @click="qrPay(scope.row)" style="color:#5074EE;" type="text">确认收款</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page.pageNumber" :page-size="queryOpt.page.pageCount" :total="total"></el-pagination>
            </div> -->
        </div>
        <div class="zt-block" style="padding:20px;">
            <el-button @click="back()" style="margin-right:50px;" size="small" type="primary">返 回</el-button>
        </div>
    </div>
</template>

<script>
    import { getPayList,qrOfflinePay } from '@/service/pay';
    import get from "lodash/get";
    export default {
        data() {
            return {
                popVisible: false,
                popTitle: null,
                queryOpt:{
                    where:{
                        states:["WAITCONFIRM","SUCCESS"]
                    }
                },
                payInfo: {
                    total: "",
                    todo: "",
                    done: "",
                    rest: ""
                },
                list:[],
                comp:null,
                order_type:null,
            }
        },
        async created() {
            this.comp = this.$store.state.loginUser.comp;
            this.order_type = this.$route.query.order_type;
            this.refreshItems();
        },
        computed: {
            privids() {
            return get(this.$store.state, "loginUser.user.privids", []);
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            refreshItems() {
                console.log('refreshItems');
                getPayList(this.$route.query.order_id,this.queryOpt).then(rst => {
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            view(item) {
                this.$router.push({
                    path:"/pay/payInfo",
                    query:{
                        order_code:item.id,
                        order_type: this.$route.query.order_type
                    }
                });
            },
            // 确认收款 ,  查看订单记录
            qrPay(item) {console.log(this.privids);
                if (this.privids.indexOf(1008) >= 0) {
                    this.$confirm("确认是否到账?", "温馨提示", {
                    confirmButtonText: "确定",cancelButtonText: "取消",type: "warning"
                    }).then(() => {
                        this.submit(item.id);
                    });
                } else {
                    this.$message.error("请财务确认是否到账，财务权限可操作!");
                }
            },
            submit(id) {
                qrOfflinePay(id).then(rst => {
                    this.$message.success("确认付款成功");
                    this.refreshItems();
                    // this.back();
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>

    

</style>